<template>
    <div class="my-profile-container">
    <!-- Image with round corners -->
    <img src="../assets/profile.png" alt="Profile logo" class="rounded-image">
  </div>
</template>


<style>
/* CSS to make the image round */
.my-profile-container {
  width: 160px; /* Adjust this value to your desired square size */
  height: auto; /* Same as width to make it square */
  padding: 10px;
}
.rounded-image {
  border-radius: 50%; /* Makes the image circular */
  width: 100%
}

</style>